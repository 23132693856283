import {
  BookUser,
  Building,
  CalendarDays,
  Container,
  FileClock,
  Home,
  MonitorCog,
  Shield,
  Siren,
  Users,
  Wallet,
} from "lucide-react"

import { SidebarNavItem } from "@/types/nav"

export const navigation: SidebarNavItem[] = [
  {
    title: "Dashboard",
    href: "/app",
    code: "dashboard",
    icon: Home,
    section: "main",
    path: ["/app"],
    items: [
      {
        title: "Overview",
        href: "/app",
        path: ["/app"],
        items: [],
      },
    ],
  },
  {
    title: "Patrol",
    href: "/app/patrol",
    code: "patrol",
    icon: Shield,
    section: "main",
    path: [
      "/app/patrol",
      "/app/patrol/detail-patrol-activity",
      "/app/patrol/export",
      "/app/patrol/area-and-assets",
      "/app/patrol/area-and-assets/edit-detail-asset",
    ],
    items: [
      {
        title: "Patrol",
        href: "/app/patrol",
        code: "patrol",
        section: "patrol",
        path: ["/app/patrol", "/app/patrol/detail-patrol-activity"],
        items: [],
      },
      {
        title: "Export",
        code: "export",
        href: "/app/patrol/export",
        section: "patrol",
        path: ["/app/patrol/export"],
        items: [],
      },
      {
        title: "Area & Assets",
        href: "/app/patrol/area-and-assets",
        code: "assets",
        section: "data",
        path: [
          "/app/patrol/area-and-assets",
          "/app/patrol/area-and-assets/edit-detail-asset",
        ],
        items: [],
      },
    ],
  },
  {
    title: "Attendance",
    href: "/app/attendance",
    code: "attendance",
    icon: CalendarDays,
    section: "main",
    path: ["/app/attendance"],
    items: [
      {
        title: "Attendance",
        href: "/app/attendance",
        path: ["/app/attendance"],
        items: [],
      },
    ],
  },
  {
    title: "Request",
    href: "/app/request",
    code: "request",
    icon: FileClock,
    section: "main",
    path: ["/app/request"],
    items: [
      {
        title: "Request",
        href: "/app/request",
        path: ["/app/request"],
        items: [],
      },
    ],
  },
  {
    title: "Panic Button",
    href: "/app/panic-button",
    code: "panicButton",
    icon: Siren,
    section: "main",
    path: [
      "/app/panic-button",
      "/app/panic-button/add",
      "/app/panic-button/detail-panic-button",
    ],
    items: [
      {
        title: "Panic Button",
        href: "/app/panic-button",
        path: [
          "/app/panic-button",
          "/app/panic-button/add",
          "/app/panic-button/detail-panic-button",
        ],
        items: [],
      },
    ],
  },
  {
    title: "Building",
    href: "/app/building",
    code: "building",
    icon: Building,
    section: "main",
    path: ["/app/building", "/app/building/company"],
    items: [
      {
        title: "Building",
        href: "/app/building",
        section: "building",
        path: ["/app/building"],
        items: [],
      },
      {
        title: "Company",
        href: "/app/building/company",
        section: "building",
        path: ["/app/building/company"],
        items: [],
      },
    ],
  },
  {
    title: "Human Resource",
    href: "/app/human-resource",
    code: "humanresource",
    icon: BookUser,
    section: "main",
    path: ["/app/human-resource"],
    items: [
      {
        title: "Human Resource",
        href: "/app/human-resource",
        path: ["/app/human-resource"],
        items: [],
      },
    ],
  },
  {
    title: "Logistic",
    href: "/app/logistic",
    code: "logistic",
    icon: Container,
    section: "main",
    path: ["/app/logistic"],
    items: [
      {
        title: "Logistic",
        href: "/app/logistic",
        path: ["/app/logistic"],
        items: [],
      },
    ],
  },
  {
    title: "Finance",
    href: "/app/finance",
    code: "finance",
    icon: Wallet,
    section: "main",
    path: ["/app/finance"],
    items: [
      {
        title: "Finance",
        href: "/app/finance",
        path: ["/app/finance"],
        items: [],
      },
    ],
  },
  {
    title: "User Management",
    href: "/app/user-management",
    code: "usermanagement",
    icon: Users,
    section: "settings",
    path: ["/app/user-management"],
    items: [
      {
        title: "User Management",
        href: "/app/user-management",
        path: ["/app/user-management"],
        items: [],
      },
    ],
  },
  {
    title: "App Setting",
    href: "/app/app-setting",
    code: "appsetting",
    icon: MonitorCog,
    section: "settings",
    path: ["/app/app-setting"],
    items: [
      {
        title: "App Setting",
        href: "/app/app-setting",
        path: ["/app/app-setting"],
        items: [],
      },
    ],
  },
]
