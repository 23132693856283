import { env } from "@/config/env"

export const convertNumberToThousand = (
  value: number | string,
  separator: string = "."
) => {
  if (typeof value === "number") {
    return value
      .toString()
      .replace(/\D/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, separator)
  }

  return value.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, separator)
}

export const getInitialsName = (name: string) => {
  return name
    .split(" ")
    .map((name) => name[0])
    .join("")
    .toUpperCase()
}

export const unmaskCharacters = (
  value: string,
  returnType: string | number = "string"
) => {
  // remove "Rp", dot, and space
  const numericValue = value.replace(/[^\d]/g, "")

  if (returnType === "number") {
    return parseInt(numericValue, 10)
  }
  return numericValue
}

export const unmaskCharactersString = (value: string): string => {
  // remove "Rp", dot, and space
  return value.replace(/[^\d]/g, "")
}

export const unmaskCharactersNumber = (value: string | number): number => {
  if (typeof value === "string") {
    const numericValue = value.replace(/[^\d]/g, "")
    return parseInt(numericValue, 10)
  }

  return value
}

export const getValidatedDate = (date: string): string => {
  const dateRegex = /^\d{4}-\d{2}-\d{2}$/
  return date && dateRegex.test(date) ? date : ""
}

export const getValidatedId = (id: string): number | null => {
  const idRegex = /^\d+$/
  return id && idRegex.test(id) ? parseInt(id) : null
}

export const getFileNameFromUrl = (url: string): string => {
  return url.split("/").pop() || ""
}

export const maskPhoneNumberWithoutCountryCode = (value: string): string => {
  const digitsOnly = value.replace(/\D/g, "")
  const input = digitsOnly.match(/(\d{0,3})(\d{0,})(\d{0,4})/)

  if (input) {
    const firstBlock: string | undefined = input[1]
    const secondBlock: string | undefined = input[2]

    let formattedNumber: string | undefined

    if (!secondBlock) {
      formattedNumber = firstBlock
    } else if (firstBlock && firstBlock.startsWith("0")) {
      formattedNumber = (firstBlock.slice(1) ?? "") + secondBlock
    } else if (firstBlock && firstBlock.startsWith("62")) {
      formattedNumber = (firstBlock.slice(2) ?? "") + secondBlock
    } else {
      formattedNumber = (firstBlock || "") + secondBlock
    }

    return formattedNumber || ""
  }

  // return empty string if input is not a number
  return ""
}

export const phoneRegex = /^\d{10,}$/

export const getImageCDN = (imagePath?: string) => {
  return imagePath ? `${env.URL_CDN}${imagePath}` : ""
}

export const getAudioCDN = (audioPath?: string) => {
  return audioPath ? `${env.URL_CDN}${audioPath}` : ""
}

export const removeArrayUrlCDN = (values: (string | undefined)[]): string[] => {
  if (!values) return []

  const filterUrlCDN = values.map((item) => {
    if (item && item.includes(env.URL_CDN)) {
      return item.replace(env.URL_CDN, "")
    }
    return item
  })

  return filterUrlCDN.filter((item): item is string => item !== undefined)
}
