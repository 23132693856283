import * as Sentry from "@sentry/react"
// import { QueryClient, useQueryClient } from "@tanstack/react-query"
// import { useMemo } from "react"
import { Navigate, RouterProvider, createBrowserRouter } from "react-router-dom"

import { ProtectedRoute } from "@/lib/auth"

import { AppRoot } from "./routes/app/root"
import ErrorPage from "./routes/error"

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter)

// const createRouter = (queryClient: QueryClient) =>
const createRouter = () =>
  sentryCreateBrowserRouter(
    [
      {
        path: "/",
        element: <Navigate to="/app" replace />,
      },
      {
        path: "/auth/login",
        errorElement: <ErrorPage />,
        lazy: async () => {
          const { LoginRoute } = await import("./routes/auth/login")
          return { Component: LoginRoute }
        },
      },
      {
        path: "/app",
        errorElement: <ErrorPage />,
        element: (
          <ProtectedRoute>
            <AppRoot />
          </ProtectedRoute>
        ),
        children: [
          {
            errorElement: <ErrorPage />,
            children: [
              {
                path: "",
                lazy: async () => {
                  const { DashboardRoute } = await import(
                    "./routes/app/dashboard"
                  )
                  return { Component: DashboardRoute }
                },
              },
              // {
              //   path: "permissions",
              //   lazy: async () => {
              //     const { PermissionsRoute } = await import(
              //       "./routes/app/permissions"
              //     )
              //     return { Component: PermissionsRoute }
              //   },
              //   loader: async () => {
              //     const { permissionsLoader } = await import(
              //       "./routes/app/permissions"
              //     )
              //     return permissionsLoader(queryClient)()
              //   },
              // },
              {
                path: "patrol",
                lazy: async () => {
                  const { PatrolRoute } = await import(
                    "./routes/app/patrol/patrol"
                  )
                  return { Component: PatrolRoute }
                },
              },
              {
                path: "patrol/detail-patrol-activity",
                lazy: async () => {
                  const { DetailPatrolActivityRoute } = await import(
                    "./routes/app/patrol/detail-patrol-activity"
                  )
                  return { Component: DetailPatrolActivityRoute }
                },
              },
              {
                path: "patrol/export",
                lazy: async () => {
                  const { ExportRoute } = await import("./routes/app/export")
                  return { Component: ExportRoute }
                },
              },
              {
                path: "patrol/area-and-assets",
                lazy: async () => {
                  const { AreaAndAssetsRoute } = await import(
                    "./routes/app/area-and-assets/area-and-assets"
                  )
                  return { Component: AreaAndAssetsRoute }
                },
              },
              {
                path: "patrol/area-and-assets/edit-detail-asset",
                lazy: async () => {
                  const { EditDetailAssetRoute } = await import(
                    "./routes/app/area-and-assets/edit-detail-asset"
                  )
                  return { Component: EditDetailAssetRoute }
                },
              },
              {
                path: "attendance",
                lazy: async () => {
                  const { AttendanceRoute } = await import(
                    "./routes/app/attendance"
                  )
                  return { Component: AttendanceRoute }
                },
              },
              {
                path: "request",
                lazy: async () => {
                  const { RequestRoute } = await import("./routes/app/request")
                  return { Component: RequestRoute }
                },
              },
              {
                path: "panic-button",
                lazy: async () => {
                  const { PanicButtonRoute } = await import(
                    "./routes/app/panic-button/panic-button"
                  )
                  return { Component: PanicButtonRoute }
                },
              },
              {
                path: "panic-button/add",
                lazy: async () => {
                  const { AddPanicButtonRoute } = await import(
                    "./routes/app/panic-button/add-panic-button"
                  )
                  return { Component: AddPanicButtonRoute }
                },
              },
              {
                path: "panic-button/detail-panic-button",
                lazy: async () => {
                  const { DetailPanicButtonRoute } = await import(
                    "./routes/app/panic-button/detail-panic-button"
                  )
                  return { Component: DetailPanicButtonRoute }
                },
              },
              {
                path: "building",
                lazy: async () => {
                  const { BuildingRoute } = await import(
                    "./routes/app/building/building"
                  )
                  return { Component: BuildingRoute }
                },
              },
              {
                path: "building/company",
                lazy: async () => {
                  const { CompanyRoute } = await import(
                    "./routes/app/building/company"
                  )
                  return { Component: CompanyRoute }
                },
              },
              {
                path: "human-resource",
                lazy: async () => {
                  const { HumanResourceRoute } = await import(
                    "./routes/app/human-resource"
                  )
                  return { Component: HumanResourceRoute }
                },
              },
              {
                path: "logistic",
                lazy: async () => {
                  const { LogisticRoute } = await import(
                    "./routes/app/logistic"
                  )
                  return { Component: LogisticRoute }
                },
              },
              {
                path: "finance",
                lazy: async () => {
                  const { FinanceRoute } = await import("./routes/app/finance")
                  return { Component: FinanceRoute }
                },
              },
              {
                path: "user-management",
                lazy: async () => {
                  const { UserManagementRoute } = await import(
                    "./routes/app/user-management"
                  )
                  return { Component: UserManagementRoute }
                },
              },
              {
                path: "app-setting",
                lazy: async () => {
                  const { AppSettingRoute } = await import(
                    "./routes/app/app-setting"
                  )
                  return { Component: AppSettingRoute }
                },
              },
            ],
          },
        ],
      },
      {
        path: "*",
        lazy: async () => {
          const { NotFoundRoute } = await import("./routes/not-found-error")
          return { Component: NotFoundRoute }
        },
      },
    ],
    {
      basename: "/",
    }
  )

export const AppRouter = () => {
  // const queryClient = useQueryClient()

  // const router = useMemo(() => createRouter(queryClient), [queryClient])
  const router = createRouter()

  return <RouterProvider router={router} />
}
